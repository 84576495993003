<template>
	<div class="app-main__inner">
		<div class="main-card mb-3">
			<div class="card-body">
				<div class="container mb-3 mt-5">
					<div class="flex justify-between">
						<div class="flex justify-between items-center space-x-4">
							<router-link :to="{ name : 'productmanagement'}"
										 class="border-2 border-purple-600 text-purple-600 hover:text-purple-600 px-3 py-2 rounded-md no-deco">
								Products
							</router-link>
							<router-link :to="{ name : 'ordermanagement'}"
										 class="border-2 border-purple-600 text-purple-600 hover:text-purple-600 px-3 py-2 rounded-md no-deco">
								Orders
							</router-link>
							<div class="flex justify-between align-items-center mr-1">
								<i class="iconly-brokenFilter mr-1"></i>
								{{ $t('filterBy') }}:
							</div>
						</div>
						<div class="flex justify-between align-items-center space-x-4">
							<div class="form-group-top">
								<input data-cy='search-app' type="text" @input="searchInputTrigger"
									   :class="loading ? 'cursor-not-allowed' : null" :disabled="loading"
									   class="w-full rounded px-3 py-2 border focus:outline-none focus:border-blue-300 text-gray-600 text-xs"
									   v-bind:placeholder="$t('searchApp')">
								<!-- <div class="input-icons">
									<i class="iconly-brokenSearch"></i>
								</div> -->
							</div>
							<button
								class="border-2 border-purple-600 text-purple-600 hover:text-purple-600 p-2 rounded-md">
								<span class="m-auto">{{ $t('exportExcel') }}</span>
							</button>
						</div>
					</div>
				</div>

				<div class="container">
					<div class="main-card mb-3 card">
						<div class="card-body">
							<div class="row sticky-table mb-3 mt-3">
								<div class="col-lg-4 col-md-4">
                                    <span>
                                        <el-date-picker
											class="w-full text-xs font-light app-input"
											v-model="value1"
											type="daterange"
											@change='dateFilter'
											value-format="yyyy-MM-dd"
											format="yyyy/MM/dd"
											start-placeholder="Startdatum"
											end-placeholder="Slutdatum">
                                        </el-date-picker>
                                    </span>
								</div>
								<div class="col-lg-2 col-md-2">
									<div class="flex md:justify-center">
										<div
											class="flex text-xs font-light justify-between align-items-center mr-1 -mt-4">
											{{ $t('apps') }}:
										</div>
										<div class="form-group">
											<select id="app-name-id" @change="changeAppName"
													class="text-xs form-select w-full">
												<option value="all">{{ $t('all') }}</option>
												<option :value="app" v-for="(app, i) in GET_APP_TYPE" :key="i">{{
														app
													}}
												</option>
											</select>
										</div>
									</div>
								</div>
							</div>
							<table style="width: 100%;" id="examplemmm"
								   class="table table-hover table-striped table-bordered">
								<thead>
								<tr>
									<th class="text-xs">{{ $t('order') }}#</th>
									<th class="text-xs">{{ $t('status') }}</th>
									<th class="text-xs">{{ $t('customer') }}</th>
									<th class="text-xs">{{ $t('country') }}</th>
									<th class="text-xs">{{ $t('amount') }}</th>
									<th class="text-xs">{{ $t('currency') }}</th>
									<th class="text-xs">{{ $t('webshop') }}</th>
									<th class="text-xs">{{ $t('action') }}</th>
								</tr>
								</thead>
								<!-- <tbody v-loading='vloading'>
									<tr v-for="(log, i) in getSystemItems" :key="i">
										<td class="text-xs font-light">{{ log.timestamp }}</td>
										<td class="text-xs font-light">{{ log.app_name }}</td>
										<td class="text-xs font-light">{{ log.error }}</td>
										<td class="text-xs font-light">{{ log.username }}</td>
									</tr>
								</tbody> -->
							</table>
							<!-- <div class="float-right mb-5">
								<paginate
								:page-count="getSystemCount"
								:click-handler="changePageNumberSystem"
								:prev-text="$t('prev')"
								:next-text="$t('next')"
								:container-class="'pagination'">
								</paginate>
							</div> -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import Paginate from 'vuejs-paginate'
import debounce from 'lodash/debounce'
import {DatePicker} from 'element-ui'

Vue.use(DatePicker)

export default {
	name: 'app-logs',
	components: {
		paginate: Paginate,
		// DatePicker
	},
	data() {
		return {
			AppLogs: [],
			copyAppLog: [],
			sytemAppLogs: [],
			copySystemLogs: [],
			value1: '',
			value2: '',
			app_type: [],
			parPage: 10,
			currentPage: 1,
			loading: false,
			currentSystemPage: 1,
			vloading: false
		}
	},
	computed: {
		getItems: function () {
			const current = this.currentPage * this.parPage
			const start = current - this.parPage
			return this.GET_APP_LOG.slice(start, current)
		},
		getPageCount: function () {
			return Math.ceil(this.GET_APP_LOG.length / this.parPage)
		},
		getSystemItems: function () {
			const current = this.currentSystemPage * this.parPage
			const start = current - this.parPage
			return this.GET_SYSTEM_LOGS.slice(start, current)
		},
		getSystemCount: function () {
			return Math.ceil(this.GET_SYSTEM_LOGS.length / this.parPage)
		},
		...mapGetters({
			USER_TOKEN: 'auth/USER_TOKEN',
			USER_REFRESH: 'auth/USER_REFRESH',
			GET_USER_COMPANY_ID: 'customer/GET_USER_COMPANY_ID',
			GET_SYSTEM_LOGS: 'customer/GET_SYSTEM_LOGS',
			GET_APP_TYPE: 'customer/GET_APP_TYPE',
			GET_APP_LOG: 'customer/GET_APP_LOG'
		})
	},
	mounted() {
		// Implementing two-way fetching
		if (this.GET_USER_COMPANY_ID) {
			// this.getAppLogs(this.GET_USER_COMPANY_ID)
			// this.getSystemAppLogs(this.GET_USER_COMPANY_ID)
		} else {
			// this.getSystemAppLogs()
			// this.getAppLogs()
		}
		// this.getSystemAppLogs()
	},
	methods: {
		searchInputTrigger: debounce(function (event) {
			if (event.target.value !== '') {
				this.filterUsers(event)
			} else {
				this.$store.commit('customer/DEFAULT_APP_LOGS')
			}
		}, 500),
		filterUsers(event) {
			this.$store.commit('customer/FILTER_USER_LOGS', event)
		},
		changePageNumberSystem(pageNum) {
			this.currentSystemPage = Number(pageNum)
		},
		changePageNumber(pageNum) {
			this.currentPage = Number(pageNum)
		},
		changeAppName(_) {
			const value = document.getElementById('app-name-id').value
			if (value === 'all') {
				this.$store.commit('customer/DEFAULT_SYSTEM_LOG')
			} else {
				this.currentSystemPage = 1
				this.$store.commit('customer/FILTER_SYSTEM_LOGS', value)
			}
		},
		dateFilter(e) {
			this.$store.commit('customer/FILTER_SYSTEM_DATE', e)
		},
		dateFilterAudit(e) {
			this.$store.commit('customer/FILTER_AUDIT_DATE', e)
		},
		getSystemAppLogs(userID) {
			const URL = userID ? this.$services.endpoints.GET_SYSTEM_LOGS + `${userID}/` : this.$services.endpoints.GET_SYSTEM_LOGS
			this.$store.dispatch('customer/getSystemAppLogs', URL)
				.then(_ => {
					this.vloading = false
				}).catch((_) => {
				this.vloading = false
			})
		},
		getAppLogs(userID) {
			const URL = userID ? this.$services.endpoints.GET_LOGS + `${userID}/` : this.$services.endpoints.GET_LOGS
			this.$store.dispatch('customer/getAppLogs', URL)
				.then(_ => {
					this.loading = false
				}).catch((_) => {
				this.loading = false
			})
		},
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.form-select {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23a0aec0'%3e%3cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3e%3c/svg%3e");
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	-webkit-print-color-adjust: exact;
	color-adjust: exact;
	background-repeat: no-repeat;
	background-color: #fff;
	border-color: #e2e8f0;
	border-width: 1px;
	border-radius: 0.25rem;
	padding-top: 0.3rem !important;
	padding-right: 2.5rem;
	padding-bottom: 0.5rem;
	padding-left: 0.75rem;
	font-size: 0.9rem !important;
	line-height: 1.5;
	background-position: right 0.5rem center;
	background-size: 1.5em 1.5em;
}

.sticky-table {
	position: -webkit-sticky;
	position: sticky;
	top: 61px;
	background-color: #fff;
	// background-color: #e8eaec;
	height: 50px;
	padding: 7px;
}

.card-body {
	padding: 0 0.9rem !important;
}

.app-main .app-main__inner {
	padding-top: 20px !important;
}

.body-tabs-shadow .body-tabs-animated {
	padding: unset !important;
}

.tab-btn.active {
	box-shadow: unset !important;
	border: 1px solid $theme-secondary-color !important;
	font-weight: 800;
	color: $theme-secondary-color !important;
	border-radius: 5px;
}

.tab-btn {
	color: $theme-secondary-light;
}

.remove-bg {
	background: unset !important;
	background-color: unset !important;
}

.custom-select {
	font-size: 0.71rem !important;
	// color: #EB2FAB !important;
	font-weight: 800;
}

.custom-search {
	font-size: 0.7rem !important;
}

.custom-search::placeholder {
	font-size: 0.7rem !important;
	// color: #EB2FAB !important;
	font-weight: 800;
}

.input-icons i {
	position: absolute;
	margin: 7px;
}

.input-icons {
	width: 100%;
	margin-bottom: 10px;
}

.icon {
	padding: 10px;
	min-width: 40px;
}

.custom-search {
	padding-left: 1.5rem !important;
}
</style>
